document.addEventListener("DOMContentLoaded", function() {
  const embedYoutube = document.getElementById("embedYoutube");
  const youtube = document.getElementById("youtube");
  const btn = document.getElementById("loadVideo");
  const img = document.getElementById("videoThumb");

  btn.onclick = function(e) {
    e.preventDefault();
    console.log(this.dataset.id);

    var iframe = document.createElement("iframe");
    var embed = "https://www.youtube.com/embed/ID?autoplay=1";

    iframe.setAttribute("src", embed.replace("ID", this.dataset.id));
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allowfullscreen", "1");
    this.parentNode.replaceChild(iframe, this);
    img.classList.add("fadeOut");
    // embedYoutube.classList.add("videoPad");
    youtube.classList.add("videoPad");
  }
});
